import Api from '@/services/Index';

const getByCode = async (code) => {
    return Api.get('/datatable/'+code);
}

const load = async (code, $this) => {

    const key = 'datatable_'+code;
    const local = $this.getWithExpiry(key);
    if(local){
        await setDatatable(local, $this);
        return;
    }

    getByCode(code).then(response=>{
        const data = response.data.data;
        $this.setWithExpiry(key, data);
        setDatatable(data, $this);
    });
}

const setDatatable = async (data, $this) => {
    data.columns.forEach(function (item){
        $this.datatable.columns.push({
            label: $this.getLocaleText(item, 'label'),
            field: item.field,
            sortable: item.sortable
        });
    });
    $this.datatable.queryParams.sort = data.default_sort;
    $this.datatable.loaded=true;
}
export default {
    getByCode,
    load
}
